<template>
  <div class="page-content">
    <nav class="page-breadcrumb" id="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">{{ breadcrumbA }}</li>
        <li class="breadcrumb-item active" aria-current="page">
          {{ breadcrumbB }}
        </li>
      </ol>
    </nav>

    <div class="row">
      <div class="col-md-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
       

              <h6 class="card-title">Zonales que en los ultimos 4 dias no cargaron novedades</h6>
            <p class="card-description">
              <span style="margin-left:5px;margin-top:5px;" class="badge badge-success">CARGADO</span>
              <span style="margin-left:5px;margin-top:5px;" class="badge badge-danger">SIN CARGAR</span>
            </p>
            <HR/>

           <!-- ROW -->
            <div class="row" style="background-color: #f7f5f5;padding-top: 20px;border: 1px solid #ecd3d3;margin-top: 5px;">
              <div class="col-md-3 grid-margin stretch-card" style="display: block;text-align: center;padding-top: 5%;" id="fecha-1"></div>
             
              <div class="col-md-9 grid-margin stretch-card" style="display: block;">
               
                <span v-for="zonales in zonales" :key="zonales" style="margin-left:5px;margin-top:5px;" class="badge badge-success" :id="'dni-'+zonales.dni+'-1'">{{zonales.nombre}}</span>
              
              </div>


            </div>
            <!-- CIERRA ROW -->

              <!-- ROW -->
            <div class="row" style="background-color: #f7f5f5;padding-top: 20px;border: 1px solid #ecd3d3;margin-top: 5px;">
              <div class="col-md-3 grid-margin stretch-card" style="display: block;text-align: center;padding-top: 5%;" id="fecha-2"></div>
             
              <div class="col-md-9 grid-margin stretch-card" style="display: block;">
               
                <span v-for="zonales in zonales" :key="zonales" style="margin-left:5px;margin-top:5px;" class="badge badge-success" :id="'dni-'+zonales.dni+'-2'">{{zonales.nombre}}</span>
              
              </div>


            </div>
            <!-- CIERRA ROW -->

              <!-- ROW -->
            <div class="row" style="background-color: #f7f5f5;padding-top: 20px;border: 1px solid #ecd3d3;margin-top: 5px;">
              <div class="col-md-3 grid-margin stretch-card" style="display: block;text-align: center;padding-top: 5%;" id="fecha-3"></div>
             
              <div class="col-md-9 grid-margin stretch-card" style="display: block;">
               
                <span v-for="zonales in zonales" :key="zonales" style="margin-left:5px;margin-top:5px;" class="badge badge-success" :id="'dni-'+zonales.dni+'-3'">{{zonales.nombre}}</span>
              
              </div>


            </div>
            <!-- CIERRA ROW -->

              <!-- ROW -->
            <div class="row" style="background-color: #f7f5f5;padding-top: 20px;border: 1px solid #ecd3d3;margin-top: 5px;">
              <div class="col-md-3 grid-margin stretch-card" style="display: block;text-align: center;padding-top: 5%;" id="fecha-4"></div>
             
              <div class="col-md-9 grid-margin stretch-card" style="display: block;">
               
                <span v-for="zonales in zonales" :key="zonales" style="margin-left:5px;margin-top:5px;" class="badge badge-success" :id="'dni-'+zonales.dni+'-4'">{{zonales.nombre}}</span>
              
              </div>


            </div>
            <!-- CIERRA ROW -->

          



          </div>
        </div>
      </div>
    </div>

    <lista-carga v-if="triggers.load"></lista-carga>
    <div class="row" v-else>
      <div class="col-md-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">



                <div class="row mb-3">
                    <div class="col">
                        <label class="mr-sm-2">Desde</label>
                        <input type="date" class="form-control mb-2 mr-sm-2" id='desde'/>
                    </div>
                    <div class="col">
                        <label class="mr-sm-2" for="inlineFormCustomSelect">Hasta</label>
                        <input type="date" class="form-control mb-2 mr-sm-2" id='hasta'/>
                    </div>
                    <div class="col">
                        <label class="mr-sm-2 w-100" for="">&nbsp;</label>
                        <button  class="btn btn-md btn-success w-100" id="buscar">Consultar Novedades</button>
                    </div>
                </div>
          


            <div class="table-responsive">
              <table
                id="novedades"
                class="table table-theme table-row v-middle"
                data-plugin="dataTable"
              >
                <thead>
                  <tr>
                    <th><span class="text-muted">Status</span></th>
                    <th><span class="text-muted">legajo</span></th>
                    <th><span class="text-muted">Nombre</span></th>
                    <th><span class="text-muted">Operación</span></th>

                    <th><span class="text-muted">Bejerman id</span></th>
                    <th><span class="text-muted">Novedad</span></th>
                    <th><span class="text-muted">Detalle</span></th>
                    <th><span class="text-muted">Horas</span></th>
                    <th><span class="text-muted">Creado</span></th>
                    <th><span class="text-muted">Fecha Novedad</span></th>
                    <th><span class="text-muted">Desde</span></th>
                    <th><span class="text-muted">Hasta</span></th>
                    <th><span class="text-muted">Días</span></th>
                    <th><span class="text-muted">Destino</span></th>
                    <th><span class="text-muted">Responsable</span></th>
                    <th><span class="text-muted">Doc</span></th>
                    <th><span class="text-muted">Obs</span></th>
                    <th><span class="text-muted">Accion</span></th>

                    <th><span class="text-muted">Auditoría</span></th>
                  </tr>
                </thead>
                <tbody>
                    <tr v-for="caso in novedades" :key="caso.legajo">
                        

                        <td v-if="caso.status=='En espera'">
                            <span  class="badge text-uppercase badge-warning">
                              {{caso.statusMensaje}}
                            </span>
                        </td>

                        <td v-if="caso.status=='Resuelto'">
                            <span  class="badge text-uppercase badge-success">
                              {{caso.statusMensaje}}
                            </span>
                        </td>

                        <td v-if="caso.status=='Observado'">
                            <span  class="badge text-uppercase badge-info">
                              {{caso.statusMensaje}}
                            </span>
                        </td>

                        <td v-if="caso.status=='Rechazado'">
                            <span  class="badge text-uppercase badge-danger">
                              {{caso.statusMensaje}}
                            </span>
                        </td>

                        <td v-if="caso.status=='-'">
                             <span  class="badge text-uppercase badge-warning">
                             En espera
                            </span>
                        </td>

                        <td :id="'key'+caso.fireId">{{ caso.legajo }}</td>
                        <td class="text-sm text-wrap">{{ caso.nombre }}</td>
                        <td class="text-sm text-wrap">{{ caso.operacion_raiz }}</td>
                        <td class="text-sm text-wrap">{{ caso.bejerman_id }}</td>
                        <td class="text-sm text-wrap">{{ caso.novedad }}</td>
                        <td class="text-sm text-wrap">{{ caso.detalle }}</td>
                        <td class="text-sm text-wrap">{{ caso.horas }}</td>
                        <td class="text-sm text-wrap">{{ caso.fecha }}</td>
                        <td class="text-sm text-wrap">{{ caso.fecha_novedad }}</td>
                        <td class="text-sm text-wrap">{{ caso.desde }}</td>
                        <td class="text-sm text-wrap">{{ caso.hasta }}</td>
                        <td class="text-sm text-wrap">{{ caso.dias }}</td>
                        <td class="text-sm text-wrap">{{ caso.destino }}</td>
                        <td class="text-sm text-wrap">{{ caso.responsable }}</td>
                       

                        <td class="text-sm text-wrap" v-if="caso.certificado=='yes'"><button type="button" class="btn btn-secondary btn-xs ml-2" v-on:click.stop.prevent="openWindow(caso.fireId)">Ver Certificado</button></td>
                        
                        <td class="text-sm text-wrap" v-if="caso.certificado=='yes_a'"><button type="button" class="btn btn-secondary btn-xs ml-2" v-on:click.stop.prevent="openWindow_a(caso.fireId)">Ver Certificado</button></td>

                        <td class="text-sm text-wrap" v-if="caso.certificado=='not'">-</td>

                        <td class="text-sm text-wrap comentario" v-if="caso.observacion=='1'"> 

                          <i :data-fire="caso.fireId" onclick="localStorage.setItem('status_id',$(this).data('fire'))" class="feather icon-mail" @click="mensajes()" style="font-size: 20px;color: green;"></i>

                          <i v-if="caso.noti=='1'" :id="'key'+caso.fireId" class="feather icon-radio" style="font-size: 20px;color: red;"><span style="visibility:hidden;font-size:1px;">Sin responder</span></i>
                          <i v-else></i>

                        </td>
                        
                        <td class="text-sm text-wrap" v-else> - </td>
                        <td>
                           
                          

                          <select v-if="isEditor('Novedades')==true"  class="custom-select mr-3 resolucionSelect" @change="cambiarStatus($event, caso)">
                                <option value="En espera" :selected="caso.status == 'En espera'">En espera</option>
                                <option value="Resuelto"  :selected="caso.status == 'Resuelto'">Resuelto</option>
                                <option value="Observado" :selected="caso.status == 'Observado'">Observado</option>
                                <option value="Rechazado" :selected="caso.status == 'Rechazado'">Rechazado</option>
                                
                            </select>

                            <select v-else class="custom-select mr-3 resolucionSelect" @change="cambiarStatus($event, caso)" disabled="true">
                                 <option value="Resuelto" :selected="caso.status == 'Resuelto'">Resuelto</option>
                                <option value="Observado" :selected="caso.status == 'Observado'">Observado</option>
                                <option value="Rechazado" :selected="caso.status == 'Rechazado'">Rechazado</option>
                            </select>
                        </td>


                        <td>
                           
                          

                          <select v-if="isEditor('Novedades')==true"  class="custom-select mr-3 resolucionSelect" @change="cambiarStatus($event, caso)">
                                <option value="">Selecciona Acción</option>
                               
                                <option value="Editar" :selected="caso.status == 'Observado'">Editar datos</option>
                               
                                
                            </select>

                            <select v-else class="custom-select mr-3 resolucionSelect" @change="cambiarStatus($event, caso)" disabled="true">
                                
                                <option value="Editar">Editar datos</option>
                               
                            </select>
                        </td>



                    </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>



 <!-- Extra large modal -->


<div class="modal fade observado_modal" tabindex="-1" role="dialog" aria-labelledby="myExtraLargeModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-xl">
    <div class="modal-content">
      <div class="card-body card-block">
        <div class="row">
          <div class="col-sm-12" style="background-color: #f9e8b7;padding: 10px;">
            
              

              <div class="form-group">
                    <label for="exampleFormControlTextarea1">Escribe la observación/detalle que explique el motivo del estado</label>
                    <textarea class="form-control" id="observacion" rows="5"></textarea>
                  </div>
           
          </div>
          <div class="card-footer">
            <div class="row">
              <div class="col-sm-6"><button class="btn btn-block btn-success" v-on:click="guardaObservacion()">Guardar</button></div>
              <div class="col-sm-6"><button  class="btn btn-block btn-danger" data-dismiss="modal" id="cerrar_modal">Cancelar</button></div>
            </div>  
          </div>
        </div>
      </div>

        <div id="historial"></div>


    </div>
  </div>
</div>


<!-- MODAL DE AUDITORIA  -->


<div class="modal fade editar_modal" tabindex="-1" role="dialog" aria-labelledby="myExtraLargeModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-xl">
    <div class="modal-content">
      <div class="card-body card-block">
        <div class="row">
          <div class="col-sm-12" style="background-color: #f9e8b7;padding: 10px;">
            
            <b> NOVEDAD DE <span id="editor_nombre"></span> </b><br>
              
<table width="100%">
    

<td colspan="3" width="70%">
   <div class="form-group formulario" data-legajo="" data-empresa="">
      <label for="exampleFormControlInput1">*Novedad * Tenga en cuenta que si modifica la novedad modificara la liquidación final</label><br>
      <select data-empresa="" data-legajos="" id="editor_novedades" class="js-example-basic novedadsel" style="width:100%;width: 100%;border: 1px solid #9b9b9b;color: #2e2929;">
        <option value="0">-- BUSCA Y SELECCIONA NOVEDAD --</option>
        <option data-condicion="y" value="PM">Presente Manual</option>
     <option data-condicion="y" data-horas="n" value="20">Enfermedad</option>
     <option data-condicion="m" data-horas="n" value="EX920">Eximición</option>
   <option data-condicion="y" data-horas="n" value="21">Accidente</option>
   <option data-condicion="y" data-horas="n" value="100">Ausencia Injustificada</option>
   <option data-condicion="m" value="667">Dia Food</option>
   <option data-condicion="m" value="668">Dia Cumpleaños</option>
   <option data-condicion="y" data-horas="n" value="38">Lic. vacuna Covid</option>
   <option data-condicion="y" data-horas="n" value="129">Lic. Maternidad</option>
   <option data-condicion="y" data-horas="n" value="25">Lic. Examen</option>
   <option data-condicion="y" data-horas="n" value="116">Lic. Excedencia</option>
   <option data-condicion="y" data-horas="n" value="24">Lic. Fallecimiento (Conyuge e Hijos)</option>
   <option data-condicion="y" data-horas="n" value="24">Lic. Fallecimiento (Padre/Madre)</option>
   <option data-condicion="y" data-horas="n" value="24">Lic. Fallecimiento (Hermanos)</option>
   <option data-condicion="y" data-horas="n" value="24">Lic. Fallecimiento (Abuelos o Suegros)</option>
   <option data-condicion="y" data-horas="n" value="22">Lic. Matrimonio</option>
   <option data-condicion="y" data-horas="n" value="26">Lic. Mudanza</option>
   <option data-condicion="y" data-horas="n" value="23">Lic. Nacimiento</option>
   <option data-condicion="y" data-horas="n" value="28">Lic. Vacaciones</option>
   <option data-condicion="y" data-horas="n" value="101">Suspension Disciplinaria</option>
   <option data-condicion="n" data-horas="n" value="122">Suspension Operartiva</option>
   <option data-condicion="c" data-horas="n" value="560">Suspension Operartiva ( SOLO CORDOBA )</option>
   <option data-condicion="c" data-horas="n" value="139">Lic. maternidad ( SOLO CORDOBA )</option>
   <option data-condicion="m" data-horas="y" value="19">Adic. horas nocturnas</option>
   <option data-condicion="m" data-horas="n" value="232">Adic. reempl. categoria transitorio</option>
   <option data-condicion="y" data-horas="n" value="27">Lic. donac. sangre</option>
   <option data-condicion="y" data-horas="n" value="31">Lic. tram. jud</option>
   <option data-condicion="m" data-horas="y" value="15">Hs. extras 50%</option>
   <option data-condicion="m" data-horas="n" value="213">Quimicos DIA DEL QUIMICO ( SOLO LINDE )</option>
   <option data-condicion="m" data-horas="y" value="119">Llegadas tarde</option>
   <option data-condicion="n" data-horas="n" value="118">Sabados NT</option>
   <option data-condicion="m" data-horas="y" value="173">Hs. extras 100%</option>
   <option data-condicion="m" data-horas="n" value="6">Feriado</option>
   <option data-condicion="m" data-horas="n" value="267">Feriado Supervisor</option>
   <option data-condicion="m" data-horas="n" value="7">Feriado 2/8 ART31 CCT</option>
   <option data-condicion="m" data-horas="n" value="212">Quimicos Feriado Trabajado ( SOLO LINDE )</option>
   <option data-condicion="x" data-horas="n" value="00">Certificado de vacunación</option>



       
      </select>
      
   </div>
   
   <br>

<div id="horas-8428">
  <div class="form-group">
    <label for="exampleFormControlInput1">Cantidad de horas</label>
    <input id="editor_horas" type="time" style="width:100%;">
    <br>
  </div>
</div>


   <br>
   <table width="100%">
      <tbody>
         <tr>
            <td>Desde *</td>
            <td>Hasta *</td>
         </tr>
         <tr>
            <td><input style="width: 50%;" value="" type="date" id="editor_inix"  min=""></td>
            <td><input style="width: 50%;" value="" type="date" id="editor_finix" min=""></td>
         </tr>
      </tbody>
   </table>

<input id="editor_fire" type="hidden" style="width:100%;">
<br>
   <div class="form-group"><label for="exampleFormControlTextarea1">Detalle</label><br><textarea style="width: 100%;border: 1px solid #9b9b9b;color: #2e2929;" class="form-control" id="editor_detalle" rows="3" width="100%"></textarea></div>
</td>


</table>
           
          </div>
          <div class="card-footer">
            <div class="row">
              <div class="col-sm-6"><button class="btn btn-block btn-success" v-on:click="guarda_auditoria()">Guardar</button></div>
              <div class="col-sm-6"><button  class="btn btn-block btn-danger" data-dismiss="modal" id="cerrar_modal">Cancelar</button></div>
            </div>  
          </div>
        </div>
      </div>

        

    </div>
  </div>
</div>



</template>

<script>
import axios from "axios";
import $ from "jquery";
import Swal from "sweetalert2";
require("datatables.net");
require("datatables.net-buttons/js/buttons.colVis.js")();
require("datatables.net-buttons/js/buttons.html5.js")();
require("datatables.net-buttons/js/buttons.flash.js")();
require("datatables.net-buttons/js/buttons.print.js")();
require("../../../public/assets/css/data-table-estilos-propios.css");
const userData = JSON.parse(sessionStorage.getItem('userData'));
const personalData = JSON.parse(sessionStorage.getItem('personal'));

import firebase from "firebase/app";
import "firebase/database";

import ListaCarga from '../ListaCarga';

const userPermission = JSON.parse(sessionStorage.getItem('userPermisos'));

export default {
    name: "novedades",
    components: {
        ListaCarga
    },
    data: () => ({
        breadcrumbA: "Inicio",
        breadcrumbB: "Novedades de Operaciones",
        descripcion: "Listado de novedades en el personal",
        novedades: [],
        zonales: [],
        triggers: {load:true},

    }),
    methods: {

        b64toBlob(b64, onsuccess, onerror) {
            var img = new Image();

            img.onerror = onerror;

            img.onload = function onload() {
                var canvas = document.createElement('canvas');
                canvas.width = img.width;
                canvas.height = img.height;

                var ctx = canvas.getContext('2d');
                ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

                canvas.toBlob(onsuccess);
            };

            img.src = b64;
        },

        openWindow(key) {




            $.ajax({
                type: 'get',
                url: 'https://aplicativos.firebaseio.com/novedades_usuarios_test/' + key + '.json?print=pretty',
                contentType: "application/json; charset=utf-8",
                traditional: true,
                success: function(datos) {


                    if (datos.certificado.includes("base64")) {

                        fetch(datos.certificado)
                            .then(response => response.blob())
                            .then(function(myBlob) {
                                var objectURL = URL.createObjectURL(myBlob);
                                window.open(objectURL, '_blank');
                            });

                    } else {
                        window.open(datos.certificado, '_blank');
                    }


                }

            });




        },

        openWindow_a(key) {


            console.log(key)


            $.ajax({
                type: 'get',
                url: 'https://aplicativos.firebaseio.com/novedades_usuarios_test/' + key + '/certificado_update.json?orderBy="$key"&limitToLast=1&print=pretty',
                contentType: "application/json; charset=utf-8",
                traditional: true,
                success: function(datos) {

                    $.each(datos, function(clave, valor) {

                        if (valor.certificado.includes("base64")) {

                            fetch(valor.certificado)
                                .then(response => response.blob())
                                .then(function(myBlob) {
                                    var objectURL = URL.createObjectURL(myBlob);
                                    window.open(objectURL, '_blank');
                                });

                        } else {
                            window.open(valor.certificado, '_blank');
                        }

                    })




                }

            });




        },

        isEditor(modulo) {

            if (userPermission.find(record => record.ModulosAccess === modulo)) {
                var status = userPermission.find(record => record.ModulosAccess === modulo).Editor;
               
                return status;
            } else {
                
                return false;
            }
        },


        getNovedad() {


            this.novedades = [];
            this.triggers.load = true;




const obtenerNombreApellidoPorLegNumero =(legNumero) => {
  // Convertimos el objeto a un array con Object.values
  const personas = Object.values(personalData);
  
  // Buscamos la persona cuyo leg_numero coincida con el valor pasado
  const personaEncontrada = personas.find(persona => persona.leg_numero == legNumero);
  
  if (personaEncontrada) {
    // Retornamos el apellido y nombre, usando trim() para limpiar posibles espacios extra
    return personaEncontrada.leg_apellido.trim()+" "+personaEncontrada.leg_nombre.trim()
  }
  
  // Si no se encontró, retornamos null o lo que necesites
  return null;
}



            axios.get("https://auth.foodservice.com.ar/?type=novedades&access_token=1234567")
                .then((response) => {
                    var vue = this;
                    var obs = 0;




                    $.each(response.data, (id, item) => {
                        let caso = item;

                        var certif_u = '-';
                        if (caso != null) {

                            if (caso.observacion != undefined) {

                                obs = 1;
                            } else {
                                obs = 0;
                            }




                            if (caso.certificado_update != undefined) {

                                $.each(caso.certificado_update, function(clab, itb) {

                                    certif_u = itb.certificado;



                                });

                                cert_c = certif_u;
                            }




                            const reemplazarIndefinido = (string) => {
                                if (string == undefined || string == null || string == '') {
                                    return "-"
                                }
                                return string
                            }



                            var fechaInicio = new Date(caso.desde).getTime();
                            var fechaFin = new Date(caso.hasta).getTime();

                            var diff = fechaFin - fechaInicio;
                            var bejerman = "-";
                            if (caso.bejerman_id) {
                                bejerman = caso.bejerman_id;
                            }




                            vue.novedades.push({
                                fireId: id,
                                desde: caso.desde,
                                destino: caso.operacion,
                                detalle: caso.detalle,
                                fecha: caso.fecha,
                                fecha_novedad: caso.fecha_novedad,
                                hasta: caso.hasta,
                                certificado: caso.certificado,
                                legajo: caso.legajo,
                                nombre: caso.nombre,
                                novedad: caso.novedad,
                                horas: reemplazarIndefinido(caso.horas),
                                noti: reemplazarIndefinido(caso.obs),
                                bejerman_id: bejerman,
                                operacion_raiz: caso.operacion_raiz,
                                responsable: caso.responsable,
                                responsable_dni: caso.responsable_dni,
                                status: reemplazarIndefinido(caso.status),
                                observacion: obs,
                                statusMensaje: caso.status,
                                dias: (diff / (1000 * 60 * 60 * 24)) + 1
                            });


                            console.log(id)
                            console.log(reemplazarIndefinido(caso.status))



                        }
                    });



                })
                .then(() => {
                  this.triggers.load = false;
                  this.$nextTick(()=>{
                    // $('#novedades').dataTable.fnDestroy();

                    if ($.fn.DataTable.isDataTable('#novedades')) {
    $('#novedades').DataTable().destroy();
}
  
  
  
                      $('#novedades').DataTable({
                          "language": {
                              "decimal": ",",
                              "thousands": ".",
                              "info": "Mostrando registros del _START_ al _END_ de un total de _TOTAL_ registros",
                              "infoEmpty": "Mostrando registros del0 al0 de un total de0 registros",
                              "infoPostFix": "",
                              "infoFiltered": "(filtrado de un total de _MAX_ registros)",
                              "loadingRecords": "Cargando...",
                              "lengthMenu": "Mostrar _MENU_ registros",
                              "paginate": {
                                  "first": "Primero",
                                  "last": "Último",
                                  "next": "Siguiente",
                                  "previous": "Anterior"
                              },
                              "processing": "Procesando...",
                              "search": "Buscar:",
                              "searchPlaceholder": "Término de búsqueda",
                              "zeroRecords": "No se encontraron resultados",
                              "emptyTable": "Ningún dato disponible en esta tabla",
                              "aria": {
                                  "sortAscending": ": Activar para ordenar la columna de manera ascendente",
                                  "sortDescending": ": Activar para ordenar la columna de manera descendente"
                              },
                          },
                          "scrollX": false,
                          "search": {
                              regex: true
                          },
                          dom: 'Bfrtip',
                          responsive: false,
                          nowrap: false,
                          "initComplete": function(settings, json) {
  
                              setTimeout(function() {
                                  console.log("completo");
                                  $('#novedades').DataTable().order([7, 'desc']).draw();
                              }, 2000)
  
  
                              // call your function here
                          },
                          buttons: [
  
                              'excel',
                              'csv',
  
                              {
                                  text: 'En espera',
                                  className: 'btn btn-warning',
                                  action: function(e, dt, node, config) {
                                      var table = $('#novedades').DataTable();
                                      table.columns(0).search("En espera").draw();
                                      table.columns(16).search("").draw();
                                  }
                              },
                              {
                                  text: 'Observado',
                                  className: 'btn btn-warning',
                                  action: function(e, dt, node, config) {
                                      var table = $('#novedades').DataTable();
                                      table.columns(0).search("Observado").draw();
                                      table.columns(16).search("").draw();
                                  }
                              },
                              {
                                  text: 'Rechazado',
                                  className: 'btn btn-warning',
                                  action: function(e, dt, node, config) {
                                      var table = $('#novedades').DataTable();
                                      table.columns(0).search("Rechazado").draw();
                                      table.columns(16).search("").draw();
                                  }
                              }, {
                                  text: 'Resuelto',
                                  className: 'btn btn-warning',
                                  action: function(e, dt, node, config) {
                                      var table = $('#novedades').DataTable();
                                      table.columns(0).search("Resuelto").draw();
                                      table.columns(16).search("").draw();
                                  }
                              }, {
                                  text: 'Sin responder',
                                  className: 'btn btn-danger',
                                  action: function(e, dt, node, config) {
                                      var table = $('#novedades').DataTable();
                                      table.columns(16).search("Sin responder").draw();
                                  }
                              },
                              {
                                  text: 'Todos',
                                  className: 'btn btn-warning',
                                  action: function(e, dt, node, config) {
                                      var table = $('#novedades').DataTable();
                                      table.columns(0).search("").draw();
                                      table.columns(6).search("").draw();
                                      table.columns(16).search("").draw();
                                  }
                              }
                          ]
                      })
  
  
  
                      const diasEntreFechas = (desde, hasta) => {
  
                          var dia_actual = desde;
                          var fechas = [];
                          while (dia_actual.isSameOrBefore(hasta)) {
                              fechas.push(dia_actual.format('YYYY-MM-DD'));
                              dia_actual.add(1, 'days');
                          }
                          return fechas;
                      };
  
                      $("#buscar").click(function() {
  
                          console.log($("#desde").val() + "hasta " + $("#hasta").val())
                          var filtro = "";
                          var desde = moment($("#desde").val());
                          var hasta = moment($("#hasta").val());
                          var results = diasEntreFechas(desde, hasta);
  
  
                          $.each(results, function(key, value) {
                              filtro += value + "|";
                          })
                          var table = $('#novedades').DataTable();
                          console.log(filtro.substring(0, filtro.length - 1));
                          table.column(10).search(filtro.substring(0, filtro.length - 1), true, false).draw();
  
  
                      })

                  })


                })

        },



        cambiarStatus(event, caso) {
            console.log(caso)

            $("#editor_nombre").html(caso.nombre);
            $("#editor_novedades option[value='" + caso.bejerman_id + "']").attr("selected", true);
            $("#editor_inix").val(caso.desde);
            $("#editor_finix").val(caso.hasta);
            $("#editor_fire").val(caso.fireId);


            var horas = caso.horas.split(":");

            var hora = String(horas[0]).padStart(2, '0');
            var minutos = String(horas[1]).padStart(2, '0');
            if (minutos == "undefined") {
                minutos = "00";
            }

            $("#editor_horas").val(hora + ":" + minutos);

            console.log(hora + ":" + minutos)


            if (event.target.value == "Observado" || event.target.value == "Rechazado") {

                window.$('.observado_modal').modal();
                localStorage.setItem("status_id", caso.fireId);
                localStorage.setItem("status_sms", event.target.value);

                caso.status = event.target.value;
                caso.statusMensaje = event.target.value;


                $("#historial").empty();

            } else if (event.target.value == "Editar") {
                window.$('.editar_modal').modal();
                localStorage.setItem("status_id", caso.fireId);
                localStorage.setItem("status_sms", event.target.value);


            } else if (event.target.value == "") {


            } else {

                firebase.database().ref('novedades_usuarios_test/' + caso.fireId).update({
                        status: event.target.value,
                        obs: 0

                    })
                    .then(() => {
                        caso.status = event.target.value;
                        caso.statusMensaje = event.target.value;
                        $("#key" + caso.fireId).hide();
                    })
                    .catch(e => {
                        console.log(e);
                    });
            }
        },


        async guarda_auditoria() {

            // console.log('Se guarda la auditoria, o no',$("#editor_detalle").val()); return null;
            let vue = this;
            if ($("#editor_detalle").val() != "") {

                firebase.database().ref('novedades_usuarios_test/' + $("#editor_fire").val()).update({
                        bejerman_id: $("#editor_novedades option:selected").val(),
                        novedad: $("#editor_novedades option:selected").text(),
                        horas: $("#editor_horas").val(),
                        desde: $("#editor_inix").val(),
                        hasta: $("#editor_finix").val(),
                        auditor: userData.leg_nombre + " " + userData.leg_apellido,
                        detalle_auditor: $("#editor_detalle").val()

                    })
                    .then(() => {
                        Swal.fire(
                            "Genial!",
                            " Novedad editada con exito",
                            "success"
                        );
                        setTimeout(function() {
                            window.$('.modal').modal('hide');
                            vue.getNovedad();
                            // location.reload();
                        }, 1000);
                    })

            } else {

                Swal.fire(
                    "Ups!, Ocurrio un error",
                    "Tiene que escribir si o si un detalle explicando el motivo del cambio",
                    "error"
                );



            }




        },

        mensajes() {


            window.$('.modal').modal();
            $("#historial").empty();

            axios.get("https://aplicativos.firebaseio.com/novedades_usuarios_test.json?orderBy=%22$key%22&equalTo=%22" + localStorage.getItem('status_id') + "%22&print=pretty")
                .then((response) => {

                    $.each(response.data, function(key, value) {
                        $.each(value.observacion, function(k, v) {
                            $("#historial").prepend("<center><div style='width:90%;padding:5px;border:1px solid #d2cbcb;margin-top:5px;margin-bottom:5px;margin:5px;text-align: left;padding-left: 15px;'><span style='color:#716868;'><i>" + v.timestamp + "</i></span><br><b>" + v.nombre + ":</b> " + v.detalle + "<br></div></center>");
                        })
                    })


                });




        },

        guardaObservacion() {



            const addZero = (i) => {
                if (i < 10) {
                    i = "0" + i;
                }
                return i;
            }

            var h = new Date();
            var hora = addZero(h.getHours());
            var minutos = ("0" + h.getMinutes()).substr(-2);
            var segundos = ("0" + h.getSeconds()).substr(-2);
            var horaActual = hora + ':' + minutos + ':' + segundos;

            var today = new Date();
            var m = today.getMonth() + 1;
            var mes = m < 10 ? "0" + m : m;
            var dia = today.getDate();
            dia = dia < 10 ? "0" + dia : dia;
            var fechaActual = today.getFullYear() + "-" + mes + "-" + dia;
            var timestamp = fechaActual + " " + horaActual;



            firebase.database().ref('novedades_usuarios_test/' + localStorage.getItem('status_id') + '/observacion').push({
                    detalle: $("#observacion").val(),
                    timestamp: timestamp,
                    nombre: userData.leg_nombre + ' ' + userData.leg_apellido

                })
                .then(() => {


                    firebase.database().ref('novedades_usuarios_test/' + localStorage.getItem('status_id')).update({
                            status: localStorage.getItem('status_sms'),
                            obs: 0

                        })
                        .then(() => {
                            $(".comentario #key" + localStorage.getItem('status_id')).hide();
                        })
                        .catch(e => {
                            console.log(e);
                        });


                    $("#observacion").val("");

                    Swal.fire(
                        "Observación creada correctamente!",
                        "Su observación fue enviada al zonal que corresponda",
                        "success"
                    );

                    setTimeout(function() {
                        $("#cerrar_modal").click();
                        console.log("ocultar noti")
                        console.log(localStorage.getItem('status_id'))
                        $(".comentario #key" + localStorage.getItem('status_id')).hide();


                        setTimeout(function() {

                            localStorage.removeItem('status_id');

                        }, 3000);

                    }, 1000);



                })
                .catch(e => {
                    Swal.fire(
                        "Ups!, Ocurrio un error",
                        "Revise su conexión a internet y vuelva a intentar",
                        "error"
                    );

                    setTimeout(function() {
                        $("#cerrar_modal").click();
                    }, 1000);
                });




        },



    },
    async mounted() {


        this.getNovedad();




        const axiosrequest1 = axios.get('https://aplicativos.firebaseio.com/distribucion.json?orderBy=%22$key%22&equalTo=%22999991%22&print=pretty');

        const axiosrequest2 = axios.get('https://auth.foodservice.com.ar/?type=personal&access_token=1234567');

        await axios.all([axiosrequest1, axiosrequest2]).then(axios.spread((zonales, personal) => {

            var zonal = [];
            var personal_data = personal.data;



            const fecha = (dias) => {


                var date_min = new Date();
                date_min.setDate(date_min.getDate() - dias);
                var mea_min = date_min.getMonth() + 1;
                var mesea_min = mea_min < 10 ? "0" + mea_min : mea_min;
                var diaea_min = date_min.getDate();
                diaea_min = diaea_min < 10 ? "0" + diaea_min : diaea_min;
                var fecha_min = date_min.getFullYear() + "-" + mesea_min + "-" + diaea_min;

                $("#fecha-" + dias).html(fecha_min);

                return fecha_min;

            }


            const result_personal = (legajo) => {

                var personal = "";
                $.each(personal_data, function(clave, value) {

                    if (value.leg_numero == legajo) {

                        personal = value.leg_numdoc;
                    }
                })
                return personal;
            }


            const verificar = (dni, dias) => {

                axios.get("https://auth.foodservice.com.ar/?type=novedades_check&fecha=" + fecha(dias) + "&responsable_dni=" + dni + "&access_token=1234567")
                    .then((response) => {

                        if (response.data == null) {
                            $("#dni-" + dni + "-" + dias).removeClass("badge-success")
                            $("#dni-" + dni + "-" + dias).addClass("badge-danger")
                        }



                    });




            }

            $.each(zonales.data,function(key,value){
                       $.each(value,function(k,v){
                          
                          zonal.push({"legajo":v.legajo,"nombre":v.nombre,"dni":result_personal(v.legajo)});
                          verificar(result_personal(v.legajo),1)
                          verificar(result_personal(v.legajo),2)
                          verificar(result_personal(v.legajo),3)
                          verificar(result_personal(v.legajo),4)

                      })
                    })
                    




            this.zonales = zonal;

        })).then(() => {});


    }
}
</script>
